import styled from 'styled-components';
import { Container, Button } from 'semantic-ui-react';
import { JobName, BrandName } from 'jobDescriptionBrandName_styled';
import { BrandLogo } from 'jobDescriptionBrandLogo_styled';

export const FullApplyDescriptionContainer = styled(Container)`
  /* We use this padding-right on Desktop screen to add space between left column and the
center screen "Collapse", "Expand" button but this messes up design on mobile */
  @media only screen and (min-width: 768px) {
    padding-right: 20px;
  }

  height: ${(props) => {
    if (props.position === 'equal') {
      /* We need to take 150px so that window overflow doesn't appear */
      return 'calc(100vh - 150px)';
    }
  }} !important;

  overflow-y: ${(props) => {
    if (props.position === 'equal') {
      return 'scroll';
    }
  }} !important;
`;

export const BlockContainer = styled.div`
  margin-bottom: 35px;
`;

export const BrandContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: ${(props) => {
    if (props.position === 'rightSideExpanded') {
      return 'wrap';
    }
  }} !important;

  /* BrandContainer component is a wrapper for JobDescriptionBrandLogo and JobDescriptionBrandName components.
  Since we use those components on quick apply and full apply page we need them to act differently depending on which side is expanded
  and which apply we are on so we use this container to pass prop and style them */
  ${JobName} {
    font-size: ${(props) => {
      if (props.position === 'rightSideExpanded') {
        return '24px';
      }
    }} !important;

    line-height: ${(props) => {
      if (props.position === 'rightSideExpanded') {
        return '29px';
      }
    }} !important;
  }

  ${BrandName} {
    font-size: ${(props) => {
      if (props.position === 'rightSideExpanded') {
        return '22px';
      }
    }} !important;
    line-height: ${(props) => {
      if (props.position === 'rightSideExpanded') {
        return '26px';
      }
    }} !important;
  }

  ${BrandLogo} {
    min-width: ${(props) => {
      if (props.position === 'rightSideExpanded') {
        return '80px';
      }
    }} !important;
  }
`;

export const ApplyFormButtonMobileWrapper = styled.div`
  margin: 30px 0;
`;

export const ApplyFormButtonContainer = styled.div`
  cursor: pointer;
  text-align: center;
`;

export const ApplyFormButtonTerms = styled.label`
  font-family: 'Neutraface2TextDemi';
`;

export const RightSideButtonControlBlockText = styled.div`
  color: #000;
  font-family: 'Neutraface2TextDemi';
  font-size: 18px;
  margin-bottom: 15px;
`;

export const RightSideButtonControlBlockTextMobile = styled.div`
  color: #000;
  font-family: 'Neutraface2TextDemi';
  font-size: 12px;
  margin-bottom: 15px;
`;

export const RightSideButtonControl = styled(Button)`
  background-color: ${(props) => props.customcolor} !important;
  color: #fff !important;
  font-family: 'Neutraface2TextBold' !important;
  font-size: 24px !important;
  height: 75px;
  line-height: 14px;
  padding: 2px 10px;
  text-decoration: none;
  width: 200px;

  @media only screen and (min-width: 900px) {
    width: 250px;
  }
`;

export const RightSideButtonControlMobile = styled(Button)`
  background-color: ${(props) => props.customcolor} !important;
  border-radius: 50px !important;
  color: #fff !important;
  font-family: 'Neutraface2TextBold' !important;
  font-size: 14px !important;
  height: 46px;
  line-height: 14px;
  padding: 2px 10px;
  text-decoration: none;
  width: 154px;
`;
export const ButtonAndCheckboxContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  text-align: left;
`;

/* RESPONSIVE */
/* =========================== */
export const DesktopScreen = styled.div`
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
`;
export const MobileScreen = styled.div`
  @media only screen and (min-width: 768px) {
    display: none !important;
  }
`;
