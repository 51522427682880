import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as Styled from 'jobDescriptionMap_styled';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import customMarker from '../../../../../../../public/assets/img/Apploi-TomTom-Location-Indicator.svg';

const JobDescriptionMap = (props) => {
  const { t } = useTranslation('jobDescription');
  const { useTomtomMaps } = useFlags();
  const mapElement = useRef();

  const center = {
    lat: (props.jobDescription && props.jobDescription.latitude) || 40.73061,
    lng: (props.jobDescription && props.jobDescription.longitude) || -73.935242,
  };

  const config = {
    height: '300px',
    maxHeight: '300px',
  };
  const url = `https://www.google.com/maps/dir/?api=1&destination=${center.lat},${center.lng}`;

  useEffect(() => {
    if (useTomtomMaps && typeof window !== 'undefined') {
      import('@tomtom-international/web-sdk-maps').then((tomtom) => {
        try {
          const tomtomMap = tomtom.map({
            key: window.tomtomAPIKey,
            container: mapElement.current,
            style: `https://api.tomtom.com/style/2/custom/style/dG9tdG9tQEBAU3h2SlRhWURVaUhlSllLRzs1NjU4ZGRjZi0zNWRhLTRiMDYtOGQ5NS1hMTVjYzNlMDkzYWE=.json?key=${window.tomtomAPIKey}`,
            center: [center.lng, center.lat],
            zoom: 15,
          });

          tomtomMap.addControl(new tomtom.FullscreenControl());

          tomtomMap.addControl(
            new tomtom.NavigationControl({
              showPitch: true,
              showExtendedRotationControls: true,
              showExtendedPitchControls: true,
            }),
          );

          const customMarkerElement = document.createElement('div');
          customMarkerElement.style.backgroundImage = `url(${customMarker})`;
          customMarkerElement.style.backgroundSize = 'cover';
          customMarkerElement.style.width = '45px';
          customMarkerElement.style.height = '54px';

          const marker = new tomtom.Marker({ element: customMarkerElement })
            .setLngLat([center.lng, center.lat])
            .addTo(tomtomMap);

          const changeCursor = (cursorStyle) => {
            const markerElement = marker.getElement();

            if (markerElement) {
              markerElement.style.cursor = cursorStyle;
            }
          };

          marker.getElement().addEventListener('mouseenter', () => {
            changeCursor('pointer');
          });

          marker.getElement().addEventListener('mouseleave', () => {
            changeCursor('');
          });

          marker.getElement().addEventListener('click', () => {
            window.open(url, '_blank');
          });

          return () => tomtomMap.remove();
        } catch (error) {
          console.error('Error initializing TomTom map:', error);

          // Fallback to Google map rendering
          setMap(null);
        }
      });
    }
  }, [useTomtomMaps, props.jobDescription]);

  const renderTomTomMap = () => (
    <Styled.MapContainer>
      <Styled.SectionHeader>{t('location')}</Styled.SectionHeader>
      <Styled.MapLocationText
        onClick={() => {
          const address = props.jobDescription && props.jobDescription.address;
          if (address) {
            window.open(url, '_blank');
          }
        }}
        style={{ cursor: 'pointer' }} // Optional: Add styles to indicate it's clickable
      >
        {props.jobDescription && props.jobDescription.address}
      </Styled.MapLocationText>
      <div
        ref={mapElement}
        style={{ height: config.height, maxHeight: config.maxHeight }}
      />
    </Styled.MapContainer>
  );

  const renderGoogleMap = () => (
    <Styled.MapContainer>
      <Styled.SectionHeader>{t('location')}</Styled.SectionHeader>
      <Styled.MapLocationText>
        {props.jobDescription && props.jobDescription.address}
      </Styled.MapLocationText>
      <GoogleMap
        mapContainerStyle={{
          height: config.height,
          maxHeight: config.maxHeight,
        }}
        center={center}
        zoom={16}
      >
        <Marker position={center} />
      </GoogleMap>
    </Styled.MapContainer>
  );
  return useTomtomMaps ? renderTomTomMap() : renderGoogleMap();
};

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
});

JobDescriptionMap.propTypes = {
  jobDescription: PropTypes.object,
};

export default connect(mapStateToProps, {})(JobDescriptionMap);
