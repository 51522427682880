import React, { useEffect, useState, useMemo } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import JobDescriptionMap from './JobDescriptionMap';

const LDJobDescriptionMapWrapper = (props) => {
  const [isClientIDAvailable, setIsClientIDAvailable] = useState(false);
  const userID =
    props?.jobDescription?.team?.parent_id || props?.jobDescription?.team_id;

  useEffect(() => {
    const waitForClientID = new Promise((resolve) => {
      const checkClientID = () => {
        if (window.ldClientID) {
          resolve();
        } else {
          setTimeout(checkClientID, 100);
        }
      };
      checkClientID();
    });

    waitForClientID.then(() => setIsClientIDAvailable(true));
  }, []);

  const LDJobDescriptionMap = useMemo(() => {
    if (!isClientIDAvailable) return null;

    return withLDProvider({
      clientSideID: window.ldClientID,
      user: {
        key: userID,
      },
    })(JobDescriptionMap);
  }, [isClientIDAvailable, userID]);

  if (!isClientIDAvailable) {
    return <div>Loading...</div>;
  }

  return <LDJobDescriptionMap {...props} />;
};

const mapStateToProps = (state) => ({
  jobDescription: state.jobDescriptionReducer.jobDescription,
});

export default connect(mapStateToProps, {})(LDJobDescriptionMapWrapper);
