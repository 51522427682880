import styled from 'styled-components';
import { Modal, Icon, Header } from 'semantic-ui-react';

export const TimeoutImageModal = styled(Modal)`
  i {
    color: #000 !important;
    right: 0 !important;
    top: 0 !important;
  }
`;
export const TimeoutImageModalContent = styled(Modal.Content)`
  background-color: #f7f3f3 !important;
  border-radius: 0px !important;
  color: #000;
  font-family: 'Neutraface';
  font-size: 18px;
  padding: 50px;
  text-align: center;
`;
export const ModalHeader = styled(Header)`
  margin: 40px 10px 20px 10px !important;

  i {
    margin: 0px !important;
    padding-top: 5px !important;
  }
`;

export const TimeoutImageModalBody = styled.h3`
  color: #000;
  font-family: 'Neutraface', 'OpenSansRegular', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
  text-align: center;
`;

/* Image modal components */
/* ============================= */

export const StateImageModal = styled.div`
  align-items: center;
  background-color: #ef4778;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom-width: 10px;
  border-radius: 80px;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: 0 auto;
  width: 80px;

  i {
    color: #fff !important;
  }
`;
export const StateImageModalIcon = styled(Icon)`
  font-size: 30px !important;
`;
